import {Application, Container, Graphics, Text, utils} from "pixi.js";

import { gsap } from "gsap";

import { CRTFilter } from '@pixi/filter-crt';
import { GlitchFilter } from '@pixi/filter-glitch';
import FontLoader from "../util/FontLoader";


class PixiApplicationHate {

    //@ts-ignore
    private _application: Application;
    //@ts-ignore
    private _previousWidth: number;
    //@ts-ignore
    private _previousHeight: number;
    //@ts-ignore
    private _crtFilter: CRTFilter;

    //@ts-ignore
    private _secondaryCrtFilter: CRTFilter;

    //@ts-ignore
    private _glitchFilter: GlitchFilter

    private _stringTextToShow: string[];

    private _mainTextSwitchDuration: number;

    private _textTimeout: any;
    //@ts-ignore
    private _mainText: Text;
    //@ts-ignore
    private _errorText: Text;
    private _mainGlitchTimeout: any;
    private _mainGlitchDuration: number;

    private _randomShape: Graphics;
    private _background: Graphics
    private _loveHateTimeout: any;

    private _colorsInverted: boolean;
    private _currentTextPointer: number;

    constructor() {
        utils.skipHello();
        this._colorsInverted = false;
        this._currentTextPointer = 0;
        this._mainGlitchDuration = 100.0;
        this._mainTextSwitchDuration = 900.0;
        this._stringTextToShow = [
            'I', 
            'AM',
            'NOT',
            'FAKE.',
            '...',
            'I', 
            'JUST',
        ];
        this._crtFilter = this._prepareCrtFilter();
        this._secondaryCrtFilter = this._prepareSecondaryCrtFilter();
        this._glitchFilter = this._prepareGlitchFilter();
        this._previousWidth = 0.0;
        this._previousHeight = 0.0;
        this._application = new Application({
            autoDensity: true,
            resolution: devicePixelRatio,
            backgroundColor: 0xE9F8FE
        });
        this._background = new Graphics();
        this._background.beginFill(0x000000);
        this._background.drawRect(0,0, window.innerWidth, window.innerHeight);
        this._background.endFill();
        this.addChild(this._background);
        this._randomShape = new Graphics();
        this.addChild(this._randomShape);
        document.body.appendChild(this._application.view);
        window.addEventListener('resize', this._onResize.bind(this));
        this._application.stage.filters = [this._glitchFilter];
        this._onResize();
        this._enterFrame();
        const fontLoader = new FontLoader();
        fontLoader.loadFonts(['Retroica'], () => {
            this._mainText = new Text('tab on the screen', {
                fontFamily: 'Retroica',
                fontSize: window.innerWidth > 700  && window.innerHeight > 750? 80 : 25,
                align: "center",
                fill: 0xffffff, // 0xFF0000, // ,
                letterSpacing: 3,
                whiteSpace: "normal",
                wordWrap: false
            });
            this._mainText.pivot.y = this._mainText.height * 0.5;
            this._mainText.pivot.x = this._mainText.width * 0.5;
            this._mainText.x =  (window.innerWidth * 0.5);
            this._mainText.y =  (window.innerHeight * 0.5);
            this._mainText.scale.x = 0.9;
            this._mainText.scale.y = 0.9;
            this.addChild(this._mainText);
            this._errorText.pivot.y = this._errorText.height * 0.5;
            this._errorText.pivot.x = this._errorText.width * 0.5;
            this._errorText.x =  (window.innerWidth * 0.5);
            this._errorText.y = window.innerWidth > 400 && window.innerHeight > 450 ?
                (window.innerHeight * 0.5) + 100 : (window.innerHeight * 0.5) + 50;
            this._errorText.scale.x = 0.9;
            this._errorText.scale.y = 0.9;
            this.addChild(this._errorText);
        });
        this._prepareGlitchTimeout();
        this._handleClick();
    }



    private _handleClick(): void {
        this._background.interactive = true;
        this._background.on('pointerdown', (event)=> {
            this._colorsInverted = !this._colorsInverted;
            this._mainText.text = this._stringTextToShow[this._currentTextPointer];
            if(this._colorsInverted) {
                this._background.clear();
                this._background.beginFill(0x000000);
                this._background.drawRect(0,0, window.innerWidth, window.innerHeight);
                this._background.endFill();
                this._mainText.style.fill = 0xffffff;
            } else {
                this._background.clear();
                this._background.beginFill(0xffffff);
                this._background.drawRect(0,0, window.innerWidth, window.innerHeight);
                this._background.endFill();
                this._mainText.style.fill = 0x000000;
            }
            if(this._currentTextPointer<this._stringTextToShow.length-1) {
                this._currentTextPointer += 1 ;
            } else {
                this._handleLoveHateText();
            }
            
        });
    }

    private _handleLoveHateText(): void {
        clearTimeout(this._loveHateTimeout);
        const textToShow = ['JUST LOVE YOU', 'JUST HATE YOU', 'JUST MISS YOU'];
        const text = textToShow[Math.floor(Math.random() * textToShow.length)] || 0;
        this._loveHateTimeout = setTimeout(()=> {
            this._mainText.text = text;
            this._handleLoveHateText();
        }, 150.0);
    }


    private _prepareGlitchTimeout(): void {
        clearTimeout(this._mainGlitchTimeout);
        this._mainGlitchTimeout = null;
        const glitchSlices = [250, 250, 500, 5, 100, 20, 20, 250];
        const glitchOffsets= [1,1,5,5,100,11,1,1,,1,1,1];
        const randomSlice = glitchSlices[Math.floor(Math.random() * glitchSlices.length)] || 0;
        const randomOffset = glitchOffsets[Math.floor(Math.random() * glitchOffsets.length)] || 0;
        this._mainGlitchTimeout = setTimeout(()=> {
            this._glitchFilter.slices = randomSlice;
            this._glitchFilter.offset = randomOffset;
            this._glitchFilter.refresh();
            this._prepareGlitchTimeout();
        }, this._mainGlitchDuration);
    }

    private _onResize(): void {
        this._reSizeRenderer();
        // Removed because the calculation is wrong but kept for future reference
        this._rePositionChildren();
        this._previousHeight = window.innerHeight;
        this._previousWidth = window.innerWidth;

    }

    private _enterFrame(): void {
        if (this._application) {
            requestAnimationFrame(this._enterFrame.bind(this));
            const stage = this._application.stage;
            this._application.renderer.render(stage);
            this._crtFilter.time += 0.15;
            this._crtFilter.seed += 0.00001;
            this._secondaryCrtFilter.time += 0.3;
            this._secondaryCrtFilter.seed += 0.00001;
        }
    }

    public getApplication(): Application {
        return this._application;
    }

    private _reSizeRenderer(): void {
        const renderer = this._application.renderer;
        renderer.resize(window.innerWidth, window.innerHeight);
    }

    private _rePositionChildren(): void {
        const renderer = this._application.renderer;
        const view = this._application.view;
        const stage = this._application.stage;
        const children = stage.children;
        children.forEach(child => {
            if(!this._isGraphic(child)) {
                child.position.set(view.width * 0.5, view.height * 0.5);
            }
        });
        if(this._errorText) {
            this._errorText.x =  (window.innerWidth * 0.5);
            this._errorText.y = window.innerWidth > 400 && window.innerHeight > 450 ?
                (window.innerHeight * 0.5) + 100 : (window.innerHeight * 0.5) + 50;
        }
        if(this._mainText) {
            this._mainText.x =  (window.innerWidth * 0.5);
            this._mainText.y =  (window.innerHeight * 0.5);
        }
        if(this._background) {
            this._background.clear();
            this._background.beginFill(0x000000);
            this._background.drawRect(0,0, window.innerWidth, window.innerHeight);
            this._background.endFill();
        }
    }

    private _calculateDeltaScaleX(): number {
        return window.innerWidth - this._previousWidth;
    }

    private _calculateDeltaScaleY(): number {
        return window.innerHeight - this._previousHeight;
    }

    public addChild(child: Container): void {
        const stage = this._application.stage;
        stage.addChild(child);
    }

    public removeChild(child: Container): void {
        const stage = this._application.stage;
        stage.removeChild(child);
    }

    private _prepareCrtFilter(): CRTFilter {
        return new CRTFilter({
            curvature: 1.0,
            lineWidth: 500.0,
            lineContrast: 0.3,
            noise: 0.1,
            noiseSize: 4,
            vignetting: 0.1,
            vignettingAlpha: 1.0,
            vignettingBlur: 0.3,
            seed: 0,
            time: 0.0,
        });
    }

    private _prepareGlitchFilter(): GlitchFilter {
        return new GlitchFilter({
            seed:0.5,
            slices: 20.0,
            offset: 10,
            direction: 0.2,
            fillMode: 2,
            red: [1.0,1.0],
            blue: [5.0, -2.0],
            green: [-5.0, 2],
        });
    }

    private _prepareSecondaryCrtFilter(): CRTFilter {
        return new CRTFilter({
            curvature: 10.0,
            lineWidth: 4.0,
            lineContrast: 0.3,
            noise: 0.0,
            noiseSize: 0.0,
            vignetting: 0.0,
            vignettingAlpha: 0.0,
            vignettingBlur: 0.0,
            seed: 0,
            time: 0.0,
        });
    }

    private _isGraphic(object: any): object is Graphics {
        return 'beginFill' in object;
    }

    public destroy(): void {
        this._application.destroy(true);
    }
}

export { PixiApplicationHate };
