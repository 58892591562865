import FontFaceObserver from 'fontfaceobserver';

class FontLoader {
    loadFonts(fontNames, callback) {
        if (window.Promise) {
            this._loadFonts(fontNames, callback);
        } else {
            console.log('Promises not supported, skipping font load');
            callback();
        }
    }

    _loadFonts(fontNames, callback) {
        const promises = fontNames.map((fontName) => {
            const loader = this._createFontFaceObserver(fontName);
            console.log(loader);
            return loader.load();
        });
        Promise.all(promises).then(callback, callback);
    }

    _createFontFaceObserver(fontName) {
        return new FontFaceObserver(fontName);
    }
}

export default FontLoader;
