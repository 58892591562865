import React, {useEffect} from "react";

import { Text } from 'pixi.js';

import { PixiApplicationHate } from "../renderer/PixiApplicationHate";

class PixiComponentHate extends React.Component<any, any> {

    // @ts-ignore
    private _pixiApplication: PixiApplicationHate;

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        // @ts-ignore
        this._pixiApplication =  this._pixiApplication || new PixiApplicationHate();
    }

    render() {
        return undefined;
    }
}

export { PixiComponentHate };
