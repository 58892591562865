import React from 'react';
import {Text, Center, Flex} from '@mantine/core';



import {PixiComponent} from "./components/PixiComponent";
import {isMobile} from 'react-device-detect';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Hate from './Hate';
import Home from './Home';


function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route index element={<Home/>}/>
    <Route path='hate' element={<Hate/>}/>
    </Routes>
  </BrowserRouter>
  );
}
export default App;
