import React from "react";

import {Text, Center, Flex} from '@mantine/core';
import {PixiComponent} from "./components/PixiComponent";

import './App.css';
import linkedIn from './bitmaps/linkedinl.png';
import twitter from './bitmaps/twitter.png';
import instagram from './bitmaps/instagram.png';

function Home() {
    return (
        <div className="App " >
        <div  className='fixed top-0 right-0'>
        </div>
        <div  className='fixed bottom-0 right-0'>
            <Text align={'right'} fz="xs">Experimental Portfolio Frontpage Material</Text>
            <Text align={'right'} fz="xs">Handcrafted with love by Copyright © 2023 M.Ergin Turk</Text>
        </div>
        <PixiComponent/>
        <Flex   mih={100}
                bg="rgba(0, 0, 0, 0)"
                gap='xl'
                justify="center"
                align="center"
                direction="row"
                wrap="wrap">
            <div>
                <a href='https://www.linkedin.com/in/mergintuerk/' target="_blank" rel="noreferrer"   className=''>
                    <img src = {linkedIn} alt ='linkedIn' width={72} height={72}  className =''/>
                </a>
            </div>
            <div>
                <a href='https://twitter.com/papa_alpha_papa' target="_blank" rel="noreferrer"   className=''>
                    <img src = {twitter} alt ='twitter' width={72} height={72}  className =''/>
                </a>
            </div>
            <div>
                <a href='https://www.instagram.com/recursive.void/' target="_blank" rel="noreferrer"   className=''>
                    <img src = {instagram} alt ='instagram' width={72} height={72}  className =''/>
                </a>
            </div>
        </Flex>
    </div>
    );
}

export default Home;