import {Application, Container, Graphics, Text, utils} from "pixi.js";

import { gsap } from "gsap";

import { CRTFilter } from '@pixi/filter-crt';
import { GlitchFilter } from '@pixi/filter-glitch';
import FontLoader from "../util/FontLoader";


class PixiApplication {

    //@ts-ignore
    private _application: Application;
    //@ts-ignore
    private _previousWidth: number;
    //@ts-ignore
    private _previousHeight: number;
    //@ts-ignore
    private _crtFilter: CRTFilter;

    //@ts-ignore
    private _secondaryCrtFilter: CRTFilter;

    //@ts-ignore
    private _glitchFilter: GlitchFilter

    private _stringTextToShow: string[];
    private _errorStringsToShot: string[];

    private _errorTimeout: any;
    private _mainTextSwitchDuration: number;

    private _textTimeout: any;
    //@ts-ignore
    private _mainText: Text;
    //@ts-ignore
    private _errorText: Text;
    private _errorDuration: number;
    private _mainGlitchTimeout: any;
    private _mainGlitchDuration: number;

    private _randomShape: Graphics;
    private _background: Graphics

    private _introTimeout: any;
    private _introTimeoutDelay: number;

    constructor() {
        utils.skipHello();
        this._introTimeoutDelay = 1500.0;
        this._errorDuration = 150.0;
        this._mainGlitchDuration = 250.0;
        this._mainTextSwitchDuration = 900.0;
        this._stringTextToShow = [
            'M. Ergin Turk',
            'Frontend Developer',
            'Based in Vienna',
            'Game Developer',
            'Photographer',
        ];
        this._errorStringsToShot = [
            '',
            '',
            '',
            '',
            '',
            '',
            '404',
            'CRITICAL ERROR',
            'ERR',
            'Creative Photography',
            'ReactJS',
            'ReactJS',
            'ReactJS',
            'ReactJS',
            'PixiJS',
            'PixiJS',
            'PixiJS',
            'PixiJS',
            'Unity',
            'TypeScript',
            'TypeScript',
            'TypeScript',
            'C SHARP',
            'JavaScript',
            'JavaScript',
            'Node.Js',
            'HTML AND CSS DUH!',
            'HTML AND CSS DUH!',
            'HTML AND CSS DUH!',
            'HTML AND CSS DUH!',
            'HTML AND CSS DUH!',
            'HTML AND CSS DUH!',
            'HTML5',
            'HTML5',
            'REST APIs',
            'Websocket',
        ];
        this._crtFilter = this._prepareCrtFilter();
        this._secondaryCrtFilter = this._prepareSecondaryCrtFilter();
        this._glitchFilter = this._prepareGlitchFilter();
        this._previousWidth = 0.0;
        this._previousHeight = 0.0;
        this._application = new Application({
            autoDensity: true,
            resolution: devicePixelRatio,
            backgroundColor: 0xE9F8FE
        });
        this._background = new Graphics();
        this._background.beginFill(0xB4D3FF);
        this._background.drawRect(0,0, window.innerWidth, window.innerHeight);
        this._background.endFill();
        this.addChild(this._background);
        this._randomShape = new Graphics();
        this.addChild(this._randomShape);
        document.body.appendChild(this._application.view);
        window.addEventListener('resize', this._onResize.bind(this));
        this._application.stage.filters = [this._crtFilter, this._secondaryCrtFilter, this._glitchFilter];
        this._onResize();
        this._enterFrame();
        const fontLoader = new FontLoader();
        fontLoader.loadFonts(['Retroica'], () => {
            this._mainText = new Text(this._getRandomTextFromMain(), {
                fontFamily: 'Retroica',
                fontSize: window.innerWidth > 400  && window.innerHeight > 450? 80 : 20,
                align: "center",
                fill: 0x252525, // 0xFF0000, // ,
                letterSpacing: 3,
                whiteSpace: "normal",
                wordWrap: false
            });
            this._mainText.pivot.y = this._mainText.height * 0.5;
            this._mainText.pivot.x = this._mainText.width * 0.5;
            this._mainText.x =  (window.innerWidth * 0.5);
            this._mainText.y =  (window.innerHeight * 0.5);
            this._mainText.scale.x = 0.9;
            this._mainText.scale.y = 0.9;
            this.addChild(this._mainText);
            this._errorText = new Text(this._getRandomTextFromError(), {
                fontFamily: 'Retroica',
                fontSize: window.innerWidth > 400 && window.innerHeight > 450 ? 50 : 15,
                align: "center",
                fill: 0xFF0000, // 0xFF0000, // ,
                letterSpacing: 3,
                whiteSpace: "normal",
                wordWrap: false
            });
            this._errorText.pivot.y = this._errorText.height * 0.5;
            this._errorText.pivot.x = this._errorText.width * 0.5;
            this._errorText.x =  (window.innerWidth * 0.5);
            this._errorText.y = window.innerWidth > 400 && window.innerHeight > 450 ?
                (window.innerHeight * 0.5) + 100 : (window.innerHeight * 0.5) + 50;
            this._errorText.scale.x = 0.9;
            this._errorText.scale.y = 0.9;
            this.addChild(this._errorText);
            this._createTextTimeout();
            this._createErrorTextTimeout();
        });
        this._prepareGlitchTimeout();
    }

    private _createErrorTextTimeout(): void {
        clearTimeout(this._errorTimeout);
        this._errorTimeout = null;
        this._errorText.scale.x = 0.95;
        this._errorText.scale.y = 0.95;
        this._errorTimeout = setTimeout(()=> {
            this._errorText.text = this._getRandomTextFromError();
            gsap.to(this._errorText.scale, {x: 1, y: 1, duration: 0.15});
            this._createErrorTextTimeout();
        }, this._errorDuration);
    }

    private _prepareGlitchTimeout(): void {
        clearTimeout(this._mainGlitchTimeout);
        this._mainGlitchTimeout = null;
        const glitchSlices = [1, 1, 5, 5, 100, 20, 20];
        const glitchOffsets= [1,1,10,10,100,25,1,1,1];
        const randomSlice = glitchSlices[Math.floor(Math.random() * glitchSlices.length)] || 0;
        const randomOffset = glitchOffsets[Math.floor(Math.random() * glitchOffsets.length)] || 0;
        this._mainGlitchTimeout = setTimeout(()=> {
            this._glitchFilter.slices = randomSlice;
            this._glitchFilter.offset = randomOffset;
            this._glitchFilter.refresh();
            this._prepareGlitchTimeout();
        }, this._mainGlitchDuration);
    }

    private _getRandomTextFromMain(): string {
        return this._stringTextToShow[Math.floor(Math.random() * this._stringTextToShow.length)] || '';
    }

    private _getRandomTextFromError(): string {
        return this._errorStringsToShot[Math.floor(Math.random() * this._errorStringsToShot.length)] || '';
    }

    private _createTextTimeout(): void {
        clearTimeout(this._textTimeout);
        this._textTimeout = null;
        this._mainText.scale.x = 0.95;
        this._mainText.scale.y = 0.95;
        this._textTimeout = setTimeout(()=> {
            this._mainText.text = this._getRandomTextFromMain();
            gsap.to(this._mainText.scale, {x: 1, y: 1, duration: .9});
            this._createTextTimeout();
        }, this._mainTextSwitchDuration);
        const colors = [0x111111, 0x990000, 0x111111, 0x111111,0x111111, 0xBAF9FF, 0xFF8300, 0xFF8300, 0xFF0000, 0xFF0000, 0x00FF1F, 0x00FF1F];
        const shapeXs = [0,0 ,window.innerWidth, window.innerWidth * 0.5 ,window.innerWidth * 0.1];
        const shapeYs = [0,0,0,0 ,window.innerHeight, window.innerHeight * 0.5 ,window.innerHeight * 0.1];
        const shapeWidth= [window.innerWidth * 0.3 ,window.innerWidth*  0.2 ,window.innerWidth, window.innerWidth * 0.5 ,window.innerWidth * 0.1];
        const shapeHeight= [window.innerHeight * 0.3 ,window.innerHeight ,window.innerHeight, window.innerHeight * 0.2 ,window.innerHeight * 0.5 ,window.innerHeight * 0.1];
        const randomX = shapeXs[Math.floor(Math.random() * shapeXs.length)] || 0;
        const randomY = shapeYs[Math.floor(Math.random() * shapeYs.length)] || 0;
        const randomWidth = shapeWidth[Math.floor(Math.random() * shapeWidth.length)] || 0;
        const randomHeight = shapeHeight[Math.floor(Math.random() * shapeHeight.length)] || 0;
        const randomColor=  colors[Math.floor(Math.random() * colors.length)] || 0;
        this._randomShape.clear();
        this._randomShape.beginFill(randomColor);
        this._randomShape.drawRect(randomX,randomY, randomWidth, randomHeight);
        this._randomShape.endFill();
    }

    private _onResize(): void {
        this._reSizeRenderer();
        // Removed because the calculation is wrong but kept for future reference
        this._rePositionChildren();
        this._previousHeight = window.innerHeight;
        this._previousWidth = window.innerWidth;

    }

    private _enterFrame(): void {
        if (this._application) {
            requestAnimationFrame(this._enterFrame.bind(this));
            const stage = this._application.stage;
            this._application.renderer.render(stage);
            this._crtFilter.time += 0.15;
            this._crtFilter.seed += 0.00001;
            this._secondaryCrtFilter.time += 0.3;
            this._secondaryCrtFilter.seed += 0.00001;
        }
    }

    public getApplication(): Application {
        return this._application;
    }

    private _reSizeRenderer(): void {
        const renderer = this._application.renderer;
        renderer.resize(window.innerWidth, window.innerHeight);
    }

    private _rePositionChildren(): void {
        const renderer = this._application.renderer;
        const view = this._application.view;
        const stage = this._application.stage;
        const children = stage.children;
        children.forEach(child => {
            if(!this._isGraphic(child)) {
                child.position.set(view.width * 0.5, view.height * 0.5);
            }
        });
        if(this._errorText) {
            this._errorText.x =  (window.innerWidth * 0.5);
            this._errorText.y = window.innerWidth > 400 && window.innerHeight > 450 ?
                (window.innerHeight * 0.5) + 100 : (window.innerHeight * 0.5) + 50;
        }
        if(this._mainText) {
            this._mainText.x =  (window.innerWidth * 0.5);
            this._mainText.y =  (window.innerHeight * 0.5);
        }
        if(this._background) {
            this._background.clear();
            this._background.beginFill(0xB4D3FF);
            this._background.drawRect(0,0, window.innerWidth, window.innerHeight);
            this._background.endFill();
        }
    }

    private _calculateDeltaScaleX(): number {
        return window.innerWidth - this._previousWidth;
    }

    private _calculateDeltaScaleY(): number {
        return window.innerHeight - this._previousHeight;
    }

    public addChild(child: Container): void {
        const stage = this._application.stage;
        stage.addChild(child);
    }

    public removeChild(child: Container): void {
        const stage = this._application.stage;
        stage.removeChild(child);
    }

    private _prepareCrtFilter(): CRTFilter {
        return new CRTFilter({
            curvature: 1.0,
            lineWidth: 500.0,
            lineContrast: 0.3,
            noise: 0.1,
            noiseSize: 4,
            vignetting: 0.1,
            vignettingAlpha: 1.0,
            vignettingBlur: 0.3,
            seed: 0,
            time: 0.0,
        });
    }

    private _prepareGlitchFilter(): GlitchFilter {
        return new GlitchFilter({
            seed:0.5,
            slices: 20.0,
            offset: 10,
            direction: 0.2,
            fillMode: 2,
            red: [1.0,1.0],
            blue: [5.0, -2.0],
            green: [-5.0, 2],
        });
    }

    private _prepareSecondaryCrtFilter(): CRTFilter {
        return new CRTFilter({
            curvature: 10.0,
            lineWidth: 4.0,
            lineContrast: 0.3,
            noise: 0.0,
            noiseSize: 0.0,
            vignetting: 0.0,
            vignettingAlpha: 0.0,
            vignettingBlur: 0.0,
            seed: 0,
            time: 0.0,
        });
    }

    private _isGraphic(object: any): object is Graphics {
        return 'beginFill' in object;
    }

    public destroy(): void {
        this._application.destroy(true);
    }
}

export { PixiApplication };
